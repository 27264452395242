import { forwardRef, useState } from 'react';
import { Typography } from '../Typography';
import { defaultTheme } from '@automata/mui-theme';
import Stack from '@mui/material/Stack';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { v4 as uuidv4 } from 'uuid';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Popover, { PopoverProps } from '@mui/material/Popover';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box, { BoxProps } from '@mui/material/Box';

export type TextFieldProps = Omit<MuiTextFieldProps, 'size' | 'popover'> & {
  infoPopover?: JSX.Element;
  infoPopoverMaxWidth?: BoxProps['maxWidth'];
  infoPopoverAnchorOrigin?: PopoverProps['anchorOrigin'];
  infoPopoverTransformOrigin?: PopoverProps['transformOrigin'];
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function Forwarded(
    {
      id = `textField-${uuidv4()}`,
      label,
      infoPopover,
      infoPopoverMaxWidth = 400,
      infoPopoverAnchorOrigin,
      infoPopoverTransformOrigin,
      ...props
    },
    ref
  ) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Stack width={props.fullWidth ? '100%' : 'inherit'}>
        <Typography
          htmlFor={id}
          component="label"
          variant="overline"
          color={defaultTheme.palette.text.primary}
          pt={1}
          pb={0.5}
          display="flex"
          alignItems="center"
          gap={0.5}
        >
          {label}
          {infoPopover && (
            <>
              <IconButton
                size="small"
                aria-label="info popover icon"
                onClick={handleClick}
              >
                <InfoOutlinedIcon fontSize="inherit" />
              </IconButton>

              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={infoPopoverAnchorOrigin}
                transformOrigin={infoPopoverTransformOrigin}
              >
                <Box maxWidth={infoPopoverMaxWidth}>{infoPopover}</Box>
              </Popover>
            </>
          )}
        </Typography>

        <FormControl>
          <MuiTextField id={id} {...props} ref={ref} size="small" />
        </FormControl>
      </Stack>
    );
  }
);
