/**
 * Generated by @openapi-codegen
 *
 * @version 4.23.0
 */
import * as reactQuery from '@tanstack/react-query';
import { useApiContext, ApiContext } from './apiContext';
import type * as Fetcher from './apiFetcher';
import { apiFetch } from './apiFetcher';
import type * as Schemas from './apiSchemas';
import type * as Responses from './apiResponses';

export type GetVersionError = Fetcher.ErrorWrapper<undefined>;

export type GetVersionVariables = ApiContext['fetcherOptions'];

export const fetchGetVersion = (
  variables: GetVersionVariables,
  signal?: AbortSignal
) =>
  apiFetch<Schemas.Versions, GetVersionError, undefined, {}, {}, {}>({
    url: '/version',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetVersion = <TData = Schemas.Versions>(
  variables: GetVersionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Versions, GetVersionError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.Versions, GetVersionError, TData>({
    queryKey: queryKeyFn({
      path: '/version',
      operationId: 'getVersion',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetVersion({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetRunHistoryDetailsPathParams = {
  /**
   * The ID of the Run History to retrieve.
   */
  runHistoryID: Schemas.Uuid;
};

export type GetRunHistoryDetailsQueryParams = {
  /**
   * The cursor from which to start the listing, leave empty for the first request.
   * A cursor is only valid if you use the same options/arguments for each request.
   */
  cursor?: string;
  /**
   * what is the date & time of the first event to return, defaults to `7 days ago`
   *
   * @format date-time
   */
  range_start?: string;
  /**
   * what is the date & time of the last event to return, defaults to `now`
   *
   * @format date-time
   */
  range_end?: string;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  count?: number;
  /**
   * What order should the entries have? Defaults to `descending`
   */
  order?: Schemas.PaginationOrder;
};

export type GetRunHistoryDetailsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetRunHistoryDetailsVariables = {
  pathParams: GetRunHistoryDetailsPathParams;
  queryParams?: GetRunHistoryDetailsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetRunHistoryDetails = (
  variables: GetRunHistoryDetailsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.RunHistoryDetailed,
    GetRunHistoryDetailsError,
    undefined,
    {},
    GetRunHistoryDetailsQueryParams,
    GetRunHistoryDetailsPathParams
  >({
    url: '/v1/run-histories/{runHistoryID}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetRunHistoryDetails = <TData = Schemas.RunHistoryDetailed>(
  variables: GetRunHistoryDetailsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RunHistoryDetailed,
      GetRunHistoryDetailsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.RunHistoryDetailed,
    GetRunHistoryDetailsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/run-histories/{runHistoryID}',
      operationId: 'getRunHistoryDetails',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetRunHistoryDetails({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetRunHistoryLogsExportPathParams = {
  /**
   * The ID of the Run History to retrieve.
   */
  runHistoryID: Schemas.Uuid;
};

export type GetRunHistoryLogsExportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetRunHistoryLogsExportVariables = {
  pathParams: GetRunHistoryLogsExportPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetRunHistoryLogsExport = (
  variables: GetRunHistoryLogsExportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ExportAuditLogs,
    GetRunHistoryLogsExportError,
    undefined,
    {},
    {},
    GetRunHistoryLogsExportPathParams
  >({
    url: '/v1/run-histories/{runHistoryID}/logs/export',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetRunHistoryLogsExport = <TData = Schemas.ExportAuditLogs>(
  variables: GetRunHistoryLogsExportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ExportAuditLogs,
      GetRunHistoryLogsExportError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ExportAuditLogs,
    GetRunHistoryLogsExportError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/run-histories/{runHistoryID}/logs/export',
      operationId: 'getRunHistoryLogsExport',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetRunHistoryLogsExport({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetRunProgressPathParams = {
  /**
   * The ID of the Run History to retrieve.
   */
  runHistoryID: Schemas.Uuid;
};

export type GetRunProgressError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetRunProgressVariables = {
  pathParams: GetRunProgressPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetRunProgress = (
  variables: GetRunProgressVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.MaestroRunProgress,
    GetRunProgressError,
    undefined,
    {},
    {},
    GetRunProgressPathParams
  >({
    url: '/v1/run-histories/{runHistoryID}/progress',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetRunProgress = <TData = Schemas.MaestroRunProgress>(
  variables: GetRunProgressVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MaestroRunProgress,
      GetRunProgressError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.MaestroRunProgress,
    GetRunProgressError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/run-histories/{runHistoryID}/progress',
      operationId: 'getRunProgress',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetRunProgress({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetRunEventLogPathParams = {
  /**
   * The ID of the Run History to retrieve.
   */
  runHistoryID: Schemas.Uuid;
};

export type GetRunEventLogError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetRunEventLogVariables = {
  pathParams: GetRunEventLogPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetRunEventLog = (
  variables: GetRunEventLogVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.HubEventLogs,
    GetRunEventLogError,
    undefined,
    {},
    {},
    GetRunEventLogPathParams
  >({
    url: '/v1/run-histories/{runHistoryID}/event-log',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetRunEventLog = <TData = Schemas.HubEventLogs>(
  variables: GetRunEventLogVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.HubEventLogs,
      GetRunEventLogError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.HubEventLogs, GetRunEventLogError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/run-histories/{runHistoryID}/event-log',
      operationId: 'getRunEventLog',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetRunEventLog({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AddTagToRunHistoryPathParams = {
  /**
   * The ID of the Run History to retrieve.
   */
  runHistoryID: Schemas.Uuid;
  /**
   * The ID of the tag to affect.
   */
  tagID: Schemas.Uuid;
};

export type AddTagToRunHistoryError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type AddTagToRunHistoryVariables = {
  pathParams: AddTagToRunHistoryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchAddTagToRunHistory = (
  variables: AddTagToRunHistoryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    AddTagToRunHistoryError,
    undefined,
    {},
    {},
    AddTagToRunHistoryPathParams
  >({
    url: '/v1/run-histories/{runHistoryID}/tags/{tagID}',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddTagToRunHistory = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddTagToRunHistoryError,
      AddTagToRunHistoryVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    AddTagToRunHistoryError,
    AddTagToRunHistoryVariables
  >({
    mutationFn: (variables: AddTagToRunHistoryVariables) =>
      fetchAddTagToRunHistory({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveTagFromRunHistoryPathParams = {
  /**
   * The ID of the Run History to retrieve.
   */
  runHistoryID: Schemas.Uuid;
  /**
   * The ID of the tag to affect.
   */
  tagID: Schemas.Uuid;
};

export type RemoveTagFromRunHistoryError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type RemoveTagFromRunHistoryVariables = {
  pathParams: RemoveTagFromRunHistoryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRemoveTagFromRunHistory = (
  variables: RemoveTagFromRunHistoryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    RemoveTagFromRunHistoryError,
    undefined,
    {},
    {},
    RemoveTagFromRunHistoryPathParams
  >({
    url: '/v1/run-histories/{runHistoryID}/tags/{tagID}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRemoveTagFromRunHistory = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemoveTagFromRunHistoryError,
      RemoveTagFromRunHistoryVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RemoveTagFromRunHistoryError,
    RemoveTagFromRunHistoryVariables
  >({
    mutationFn: (variables: RemoveTagFromRunHistoryVariables) =>
      fetchRemoveTagFromRunHistory({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetBatchDetailsPathParams = {
  /**
   * The ID of the Batch to retrieve.
   */
  batchID: Schemas.Uuid;
};

export type GetBatchDetailsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetBatchDetailsVariables = {
  pathParams: GetBatchDetailsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetBatchDetails = (
  variables: GetBatchDetailsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.BatchTrace,
    GetBatchDetailsError,
    undefined,
    {},
    {},
    GetBatchDetailsPathParams
  >({ url: '/v1/batches/{batchID}', method: 'get', ...variables, signal });

export const useGetBatchDetails = <TData = Schemas.BatchTrace>(
  variables: GetBatchDetailsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.BatchTrace, GetBatchDetailsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.BatchTrace, GetBatchDetailsError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/batches/{batchID}',
      operationId: 'getBatchDetails',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetBatchDetails({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetWorkflowDetailsPathParams = {
  /**
   * The ID of the Workflow to retrieve.
   */
  workflowID: Schemas.Uuid;
};

export type GetWorkflowDetailsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkflowDetailsVariables = {
  pathParams: GetWorkflowDetailsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkflowDetails = (
  variables: GetWorkflowDetailsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.WorkflowDetailed,
    GetWorkflowDetailsError,
    undefined,
    {},
    {},
    GetWorkflowDetailsPathParams
  >({ url: '/v1/workflows/{workflowID}', method: 'get', ...variables, signal });

export const useGetWorkflowDetails = <TData = Schemas.WorkflowDetailed>(
  variables: GetWorkflowDetailsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.WorkflowDetailed,
      GetWorkflowDetailsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.WorkflowDetailed,
    GetWorkflowDetailsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/workflows/{workflowID}',
      operationId: 'getWorkflowDetails',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkflowDetails({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateWorkflowPathParams = {
  /**
   * The ID of the Workflow to retrieve.
   */
  workflowID: Schemas.Uuid;
};

export type UpdateWorkflowHeaders = {
  ['X-Request-Authorization']?: string;
};

export type UpdateWorkflowError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type UpdateWorkflowRequestBody = Schemas.Reason & {
  payload: Schemas.WorkflowPayload;
};

export type UpdateWorkflowVariables = {
  body?: UpdateWorkflowRequestBody;
  headers?: UpdateWorkflowHeaders;
  pathParams: UpdateWorkflowPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateWorkflow = (
  variables: UpdateWorkflowVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.WorkflowDetailed,
    UpdateWorkflowError,
    UpdateWorkflowRequestBody,
    UpdateWorkflowHeaders,
    {},
    UpdateWorkflowPathParams
  >({ url: '/v1/workflows/{workflowID}', method: 'put', ...variables, signal });

export const useUpdateWorkflow = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.WorkflowDetailed,
      UpdateWorkflowError,
      UpdateWorkflowVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.WorkflowDetailed,
    UpdateWorkflowError,
    UpdateWorkflowVariables
  >({
    mutationFn: (variables: UpdateWorkflowVariables) =>
      fetchUpdateWorkflow({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteWorkflowPathParams = {
  /**
   * The ID of the Workflow to retrieve.
   */
  workflowID: Schemas.Uuid;
};

export type DeleteWorkflowHeaders = {
  ['X-Request-Authorization']?: string;
};

export type DeleteWorkflowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type DeleteWorkflowVariables = {
  body: Schemas.Reason;
  headers?: DeleteWorkflowHeaders;
  pathParams: DeleteWorkflowPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteWorkflow = (
  variables: DeleteWorkflowVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DeleteWorkflowError,
    Schemas.Reason,
    DeleteWorkflowHeaders,
    {},
    DeleteWorkflowPathParams
  >({
    url: '/v1/workflows/{workflowID}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteWorkflow = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteWorkflowError,
      DeleteWorkflowVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteWorkflowError,
    DeleteWorkflowVariables
  >({
    mutationFn: (variables: DeleteWorkflowVariables) =>
      fetchDeleteWorkflow({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DuplicateWorkflowPathParams = {
  /**
   * The ID of the Workflow to retrieve.
   */
  workflowID: Schemas.Uuid;
};

export type DuplicateWorkflowHeaders = {
  ['X-Request-Authorization']?: string;
};

export type DuplicateWorkflowError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type DuplicateWorkflowVariables = {
  body: Schemas.DuplicateResourcePayload;
  headers?: DuplicateWorkflowHeaders;
  pathParams: DuplicateWorkflowPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDuplicateWorkflow = (
  variables: DuplicateWorkflowVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Workflow,
    DuplicateWorkflowError,
    Schemas.DuplicateResourcePayload,
    DuplicateWorkflowHeaders,
    {},
    DuplicateWorkflowPathParams
  >({
    url: '/v1/workflows/{workflowID}/duplicate',
    method: 'post',
    ...variables,
    signal,
  });

export const useDuplicateWorkflow = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Workflow,
      DuplicateWorkflowError,
      DuplicateWorkflowVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Workflow,
    DuplicateWorkflowError,
    DuplicateWorkflowVariables
  >({
    mutationFn: (variables: DuplicateWorkflowVariables) =>
      fetchDuplicateWorkflow({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetWorkflowDeploymentCandidatesPathParams = {
  /**
   * The ID of the Workflow to retrieve.
   */
  workflowID: Schemas.Uuid;
};

export type GetWorkflowDeploymentCandidatesQueryParams = {
  /**
   * A string used to filter results by.
   */
  filter?: string;
};

export type GetWorkflowDeploymentCandidatesError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkflowDeploymentCandidatesVariables = {
  pathParams: GetWorkflowDeploymentCandidatesPathParams;
  queryParams?: GetWorkflowDeploymentCandidatesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkflowDeploymentCandidates = (
  variables: GetWorkflowDeploymentCandidatesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.WorkflowDeployCandidates,
    GetWorkflowDeploymentCandidatesError,
    undefined,
    {},
    GetWorkflowDeploymentCandidatesQueryParams,
    GetWorkflowDeploymentCandidatesPathParams
  >({
    url: '/v1/workflows/{workflowID}/deployment-candidates',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkflowDeploymentCandidates = <
  TData = Schemas.WorkflowDeployCandidates
>(
  variables: GetWorkflowDeploymentCandidatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.WorkflowDeployCandidates,
      GetWorkflowDeploymentCandidatesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.WorkflowDeployCandidates,
    GetWorkflowDeploymentCandidatesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/workflows/{workflowID}/deployment-candidates',
      operationId: 'getWorkflowDeploymentCandidates',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkflowDeploymentCandidates(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type RunInstructionsCommitPathParams = {
  /**
   * The ID of the Workflow to retrieve.
   */
  workflowID: Schemas.Uuid;
  /**
   * The type of the run instructions to affect.
   */
  runInstructionType: Schemas.RunInstructionType;
};

export type RunInstructionsCommitError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type RunInstructionsCommitVariables = {
  pathParams: RunInstructionsCommitPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRunInstructionsCommit = (
  variables: RunInstructionsCommitVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    RunInstructionsCommitError,
    undefined,
    {},
    {},
    RunInstructionsCommitPathParams
  >({
    url: '/v1/workflows/{workflowID}/run-instructions/{runInstructionType}/commit',
    method: 'post',
    ...variables,
    signal,
  });

export const useRunInstructionsCommit = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RunInstructionsCommitError,
      RunInstructionsCommitVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RunInstructionsCommitError,
    RunInstructionsCommitVariables
  >({
    mutationFn: (variables: RunInstructionsCommitVariables) =>
      fetchRunInstructionsCommit({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RunInstructionsSkipPathParams = {
  /**
   * The ID of the Workflow to retrieve.
   */
  workflowID: Schemas.Uuid;
  /**
   * The type of the run instructions to affect.
   */
  runInstructionType: Schemas.RunInstructionType;
};

export type RunInstructionsSkipError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type RunInstructionsSkipVariables = {
  pathParams: RunInstructionsSkipPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRunInstructionsSkip = (
  variables: RunInstructionsSkipVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    RunInstructionsSkipError,
    undefined,
    {},
    {},
    RunInstructionsSkipPathParams
  >({
    url: '/v1/workflows/{workflowID}/run-instructions/{runInstructionType}/skip',
    method: 'post',
    ...variables,
    signal,
  });

export const useRunInstructionsSkip = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RunInstructionsSkipError,
      RunInstructionsSkipVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RunInstructionsSkipError,
    RunInstructionsSkipVariables
  >({
    mutationFn: (variables: RunInstructionsSkipVariables) =>
      fetchRunInstructionsSkip({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddTagToWorkflowPathParams = {
  /**
   * The ID of the Workflow to retrieve.
   */
  workflowID: Schemas.Uuid;
  /**
   * The ID of the tag to affect.
   */
  tagID: Schemas.Uuid;
};

export type AddTagToWorkflowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type AddTagToWorkflowVariables = {
  pathParams: AddTagToWorkflowPathParams;
} & ApiContext['fetcherOptions'];

export const fetchAddTagToWorkflow = (
  variables: AddTagToWorkflowVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    AddTagToWorkflowError,
    undefined,
    {},
    {},
    AddTagToWorkflowPathParams
  >({
    url: '/v1/workflows/{workflowID}/tags/{tagID}',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddTagToWorkflow = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddTagToWorkflowError,
      AddTagToWorkflowVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    AddTagToWorkflowError,
    AddTagToWorkflowVariables
  >({
    mutationFn: (variables: AddTagToWorkflowVariables) =>
      fetchAddTagToWorkflow({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveTagFromWorkflowPathParams = {
  /**
   * The ID of the Workflow to retrieve.
   */
  workflowID: Schemas.Uuid;
  /**
   * The ID of the tag to affect.
   */
  tagID: Schemas.Uuid;
};

export type RemoveTagFromWorkflowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type RemoveTagFromWorkflowVariables = {
  pathParams: RemoveTagFromWorkflowPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRemoveTagFromWorkflow = (
  variables: RemoveTagFromWorkflowVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    RemoveTagFromWorkflowError,
    undefined,
    {},
    {},
    RemoveTagFromWorkflowPathParams
  >({
    url: '/v1/workflows/{workflowID}/tags/{tagID}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRemoveTagFromWorkflow = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemoveTagFromWorkflowError,
      RemoveTagFromWorkflowVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RemoveTagFromWorkflowError,
    RemoveTagFromWorkflowVariables
  >({
    mutationFn: (variables: RemoveTagFromWorkflowVariables) =>
      fetchRemoveTagFromWorkflow({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetMaestroVersionsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetMaestroVersionsVariables = ApiContext['fetcherOptions'];

export const fetchGetMaestroVersions = (
  variables: GetMaestroVersionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.MaestroVersions,
    GetMaestroVersionsError,
    undefined,
    {},
    {},
    {}
  >({ url: '/v1/maestro/versions', method: 'get', ...variables, signal });

export const useGetMaestroVersions = <TData = Schemas.MaestroVersions>(
  variables: GetMaestroVersionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MaestroVersions,
      GetMaestroVersionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.MaestroVersions,
    GetMaestroVersionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/maestro/versions',
      operationId: 'getMaestroVersions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMaestroVersions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetEvalVersionsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetEvalVersionsVariables = ApiContext['fetcherOptions'];

export const fetchGetEvalVersions = (
  variables: GetEvalVersionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<Schemas.EvalVersions, GetEvalVersionsError, undefined, {}, {}, {}>({
    url: '/v1/evals/versions',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetEvalVersions = <TData = Schemas.EvalVersions>(
  variables: GetEvalVersionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.EvalVersions,
      GetEvalVersionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.EvalVersions, GetEvalVersionsError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/v1/evals/versions',
        operationId: 'getEvalVersions',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchGetEvalVersions({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }
  );
};

export type GetArchetypeVersionsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetArchetypeVersionsVariables = ApiContext['fetcherOptions'];

export const fetchGetArchetypeVersions = (
  variables: GetArchetypeVersionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ArchetypeVersions,
    GetArchetypeVersionsError,
    undefined,
    {},
    {},
    {}
  >({ url: '/v1/archetypes/versions', method: 'get', ...variables, signal });

export const useGetArchetypeVersions = <TData = Schemas.ArchetypeVersions>(
  variables: GetArchetypeVersionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ArchetypeVersions,
      GetArchetypeVersionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ArchetypeVersions,
    GetArchetypeVersionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/archetypes/versions',
      operationId: 'getArchetypeVersions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetArchetypeVersions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDeviceDataPathParams = {
  /**
   * The ID or serial of the device to affect.
   */
  deviceIDOrSerial: Schemas.Uuid | Schemas.RequiredString;
};

export type GetDeviceDataQueryParams = {
  /**
   * what is the date & time of the first event to return, defaults to `7 days ago`
   *
   * @format date-time
   */
  range_start?: string;
  /**
   * what is the date & time of the last event to return, defaults to `now`
   *
   * @format date-time
   */
  range_end?: string;
};

export type GetDeviceDataError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetDeviceDataVariables = {
  pathParams: GetDeviceDataPathParams;
  queryParams?: GetDeviceDataQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetDeviceData = (
  variables: GetDeviceDataVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DeviceData,
    GetDeviceDataError,
    undefined,
    {},
    GetDeviceDataQueryParams,
    GetDeviceDataPathParams
  >({
    url: '/v1/devices/{deviceIDOrSerial}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetDeviceData = <TData = Schemas.DeviceData>(
  variables: GetDeviceDataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DeviceData, GetDeviceDataError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DeviceData, GetDeviceDataError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/devices/{deviceIDOrSerial}',
      operationId: 'getDeviceData',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDeviceData({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteDevicePathParams = {
  /**
   * The ID or serial of the device to affect.
   */
  deviceIDOrSerial: Schemas.Uuid | Schemas.RequiredString;
};

export type DeleteDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type DeleteDeviceVariables = {
  pathParams: DeleteDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteDevice = (
  variables: DeleteDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DeleteDeviceError,
    undefined,
    {},
    {},
    DeleteDevicePathParams
  >({
    url: '/v1/devices/{deviceIDOrSerial}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteDeviceError,
      DeleteDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteDeviceError,
    DeleteDeviceVariables
  >({
    mutationFn: (variables: DeleteDeviceVariables) =>
      fetchDeleteDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetDeviceStateHistoryPathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type GetDeviceStateHistoryQueryParams = {
  /**
   * what is the date & time of the first event to return, defaults to `7 days ago`
   *
   * @format date-time
   */
  range_start?: string;
  /**
   * what is the date & time of the last event to return, defaults to `now`
   *
   * @format date-time
   */
  range_end?: string;
  /**
   * The cursor from which to start the listing, leave empty for the first request.
   * A cursor is only valid if you use the same options/arguments for each request.
   */
  cursor?: string;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  count?: number;
  /**
   * What order should the entries have? Defaults to `descending`
   */
  order?: Schemas.PaginationOrder;
  /**
   * The ID of the Run History to filter by.
   */
  run_history_id?: Schemas.Uuid;
};

export type GetDeviceStateHistoryError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongRangeError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetDeviceStateHistoryVariables = {
  pathParams: GetDeviceStateHistoryPathParams;
  queryParams?: GetDeviceStateHistoryQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetDeviceStateHistory = (
  variables: GetDeviceStateHistoryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.PaginatedDeviceDataStates,
    GetDeviceStateHistoryError,
    undefined,
    {},
    GetDeviceStateHistoryQueryParams,
    GetDeviceStateHistoryPathParams
  >({
    url: '/v1/devices/{deviceID}/history/state',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetDeviceStateHistory = <
  TData = Schemas.PaginatedDeviceDataStates
>(
  variables: GetDeviceStateHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PaginatedDeviceDataStates,
      GetDeviceStateHistoryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PaginatedDeviceDataStates,
    GetDeviceStateHistoryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/devices/{deviceID}/history/state',
      operationId: 'getDeviceStateHistory',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDeviceStateHistory({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDeviceMetricsHistoryPathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type GetDeviceMetricsHistoryQueryParams = {
  /**
   * what is the date & time of the first event to return, defaults to `7 days ago`
   *
   * @format date-time
   */
  range_start?: string;
  /**
   * what is the date & time of the last event to return, defaults to `now`
   *
   * @format date-time
   */
  range_end?: string;
  /**
   * The cursor from which to start the listing, leave empty for the first request.
   * A cursor is only valid if you use the same options/arguments for each request.
   */
  cursor?: string;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  count?: number;
  /**
   * What order should the entries have? Defaults to `descending`
   */
  order?: Schemas.PaginationOrder;
  metrics?: string[];
  /**
   * The ID of the Run History to filter by.
   */
  run_history_id?: Schemas.Uuid;
};

export type GetDeviceMetricsHistoryError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongRangeError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetDeviceMetricsHistoryVariables = {
  pathParams: GetDeviceMetricsHistoryPathParams;
  queryParams?: GetDeviceMetricsHistoryQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetDeviceMetricsHistory = (
  variables: GetDeviceMetricsHistoryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.PaginatedDeviceDataMetrics,
    GetDeviceMetricsHistoryError,
    undefined,
    {},
    GetDeviceMetricsHistoryQueryParams,
    GetDeviceMetricsHistoryPathParams
  >({
    url: '/v1/devices/{deviceID}/history/metrics',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetDeviceMetricsHistory = <
  TData = Schemas.PaginatedDeviceDataMetrics
>(
  variables: GetDeviceMetricsHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PaginatedDeviceDataMetrics,
      GetDeviceMetricsHistoryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PaginatedDeviceDataMetrics,
    GetDeviceMetricsHistoryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/devices/{deviceID}/history/metrics',
      operationId: 'getDeviceMetricsHistory',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDeviceMetricsHistory({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDeviceLogsHistoryPathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type GetDeviceLogsHistoryQueryParams = {
  /**
   * what is the date & time of the first event to return, defaults to `7 days ago`
   *
   * @format date-time
   */
  range_start?: string;
  /**
   * what is the date & time of the last event to return, defaults to `now`
   *
   * @format date-time
   */
  range_end?: string;
  /**
   * The cursor from which to start the listing, leave empty for the first request.
   * A cursor is only valid if you use the same options/arguments for each request.
   */
  cursor?: string;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  count?: number;
  /**
   * What order should the entries have? Defaults to `descending`
   */
  order?: Schemas.PaginationOrder;
  filter?: string;
  /**
   * The ID of the Run History to filter by.
   */
  run_history_id?: Schemas.Uuid;
};

export type GetDeviceLogsHistoryError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongRangeError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetDeviceLogsHistoryVariables = {
  pathParams: GetDeviceLogsHistoryPathParams;
  queryParams?: GetDeviceLogsHistoryQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetDeviceLogsHistory = (
  variables: GetDeviceLogsHistoryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.PaginatedDeviceDataLogs,
    GetDeviceLogsHistoryError,
    undefined,
    {},
    GetDeviceLogsHistoryQueryParams,
    GetDeviceLogsHistoryPathParams
  >({
    url: '/v1/devices/{deviceID}/history/logs',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetDeviceLogsHistory = <
  TData = Schemas.PaginatedDeviceDataLogs
>(
  variables: GetDeviceLogsHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PaginatedDeviceDataLogs,
      GetDeviceLogsHistoryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PaginatedDeviceDataLogs,
    GetDeviceLogsHistoryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/devices/{deviceID}/history/logs',
      operationId: 'getDeviceLogsHistory',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDeviceLogsHistory({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDeviceRunHistoriesHistoryPathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type GetDeviceRunHistoriesHistoryQueryParams = {
  /**
   * what is the date & time of the first event to return, defaults to `7 days ago`
   *
   * @format date-time
   */
  range_start?: string;
  /**
   * what is the date & time of the last event to return, defaults to `now`
   *
   * @format date-time
   */
  range_end?: string;
  /**
   * The cursor from which to start the listing, leave empty for the first request.
   * A cursor is only valid if you use the same options/arguments for each request.
   */
  cursor?: string;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  count?: number;
  /**
   * What order should the entries have? Defaults to `descending`
   */
  order?: Schemas.PaginationOrder;
  /**
   * The tag to filter by.
   */
  tags?: Schemas.Uuid[];
};

export type GetDeviceRunHistoriesHistoryError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongRangeError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetDeviceRunHistoriesHistoryVariables = {
  pathParams: GetDeviceRunHistoriesHistoryPathParams;
  queryParams?: GetDeviceRunHistoriesHistoryQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetDeviceRunHistoriesHistory = (
  variables: GetDeviceRunHistoriesHistoryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.PaginatedDeviceRunHistories,
    GetDeviceRunHistoriesHistoryError,
    undefined,
    {},
    GetDeviceRunHistoriesHistoryQueryParams,
    GetDeviceRunHistoriesHistoryPathParams
  >({
    url: '/v1/devices/{deviceID}/history/run-histories',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetDeviceRunHistoriesHistory = <
  TData = Schemas.PaginatedDeviceRunHistories
>(
  variables: GetDeviceRunHistoriesHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PaginatedDeviceRunHistories,
      GetDeviceRunHistoriesHistoryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PaginatedDeviceRunHistories,
    GetDeviceRunHistoriesHistoryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/devices/{deviceID}/history/run-histories',
      operationId: 'getDeviceRunHistoriesHistory',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDeviceRunHistoriesHistory(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type RenameDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type RenameDeviceHeaders = {
  ['X-Request-Authorization']?: string;
};

export type RenameDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.DuplicateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type RenameDeviceVariables = {
  body: Schemas.RenamePayload;
  headers?: RenameDeviceHeaders;
  pathParams: RenameDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchRenameDevice = (
  variables: RenameDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    RenameDeviceError,
    Schemas.RenamePayload,
    RenameDeviceHeaders,
    {},
    RenameDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/rename',
    method: 'put',
    ...variables,
    signal,
  });

export const useRenameDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RenameDeviceError,
      RenameDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RenameDeviceError,
    RenameDeviceVariables
  >({
    mutationFn: (variables: RenameDeviceVariables) =>
      fetchRenameDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LoadWorkflowOnDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type LoadWorkflowOnDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type LoadWorkflowOnDeviceRequestBody = Schemas.Reason & {
  payload: Schemas.LoadPayload;
  /**
   * @format binary
   */
  file?: Blob;
};

export type LoadWorkflowOnDeviceVariables = {
  body?: LoadWorkflowOnDeviceRequestBody;
  pathParams: LoadWorkflowOnDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchLoadWorkflowOnDevice = (
  variables: LoadWorkflowOnDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    LoadWorkflowOnDeviceError,
    LoadWorkflowOnDeviceRequestBody,
    {},
    {},
    LoadWorkflowOnDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/state/load',
    method: 'post',
    ...variables,
    signal,
  });

export const useLoadWorkflowOnDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      LoadWorkflowOnDeviceError,
      LoadWorkflowOnDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    LoadWorkflowOnDeviceError,
    LoadWorkflowOnDeviceVariables
  >({
    mutationFn: (variables: LoadWorkflowOnDeviceVariables) =>
      fetchLoadWorkflowOnDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeployWorkflowToDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type DeployWorkflowToDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type DeployWorkflowToDeviceRequestBody = Schemas.Reason & {
  payload: Schemas.LoadPayload;
  parameter_files?: Blob[];
  reference_data_files?: Blob[];
};

export type DeployWorkflowToDeviceVariables = {
  body?: DeployWorkflowToDeviceRequestBody;
  pathParams: DeployWorkflowToDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeployWorkflowToDevice = (
  variables: DeployWorkflowToDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DeployWorkflowToDeviceError,
    DeployWorkflowToDeviceRequestBody,
    {},
    {},
    DeployWorkflowToDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/deploy',
    method: 'post',
    ...variables,
    signal,
  });

export const useDeployWorkflowToDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeployWorkflowToDeviceError,
      DeployWorkflowToDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeployWorkflowToDeviceError,
    DeployWorkflowToDeviceVariables
  >({
    mutationFn: (variables: DeployWorkflowToDeviceVariables) =>
      fetchDeployWorkflowToDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetDeviceConfigurationPathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type GetDeviceConfigurationError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetDeviceConfigurationVariables = {
  pathParams: GetDeviceConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetDeviceConfiguration = (
  variables: GetDeviceConfigurationVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DeviceConfiguration,
    GetDeviceConfigurationError,
    undefined,
    {},
    {},
    GetDeviceConfigurationPathParams
  >({
    url: '/v1/devices/{deviceID}/state/configure',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetDeviceConfiguration = <TData = Schemas.DeviceConfiguration>(
  variables: GetDeviceConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.DeviceConfiguration,
      GetDeviceConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.DeviceConfiguration,
    GetDeviceConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/devices/{deviceID}/state/configure',
      operationId: 'getDeviceConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDeviceConfiguration({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ConfigureDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type ConfigureDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type ConfigureDeviceRequestBody = Schemas.Reason & {
  payload: Schemas.ConfigurePayload;
};

export type ConfigureDeviceVariables = {
  body?: ConfigureDeviceRequestBody;
  pathParams: ConfigureDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchConfigureDevice = (
  variables: ConfigureDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ConfigureDeviceError,
    ConfigureDeviceRequestBody,
    {},
    {},
    ConfigureDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/state/configure',
    method: 'post',
    ...variables,
    signal,
  });

export const useConfigureDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ConfigureDeviceError,
      ConfigureDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ConfigureDeviceError,
    ConfigureDeviceVariables
  >({
    mutationFn: (variables: ConfigureDeviceVariables) =>
      fetchConfigureDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type StartDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type StartDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type StartDeviceRequestBody = Schemas.Reason & {
  payload: Schemas.StartParameters;
};

export type StartDeviceVariables = {
  body?: StartDeviceRequestBody;
  pathParams: StartDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchStartDevice = (
  variables: StartDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    StartDeviceError,
    StartDeviceRequestBody,
    {},
    {},
    StartDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/state/start',
    method: 'post',
    ...variables,
    signal,
  });

export const useStartDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      StartDeviceError,
      StartDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    StartDeviceError,
    StartDeviceVariables
  >({
    mutationFn: (variables: StartDeviceVariables) =>
      fetchStartDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PauseDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type PauseDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type PauseDeviceVariables = {
  body: Schemas.Reason;
  pathParams: PauseDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPauseDevice = (
  variables: PauseDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    PauseDeviceError,
    Schemas.Reason,
    {},
    {},
    PauseDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/state/pause',
    method: 'post',
    ...variables,
    signal,
  });

export const usePauseDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PauseDeviceError,
      PauseDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PauseDeviceError,
    PauseDeviceVariables
  >({
    mutationFn: (variables: PauseDeviceVariables) =>
      fetchPauseDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ConfirmPauseDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type ConfirmPauseDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type ConfirmPauseDeviceVariables = {
  pathParams: ConfirmPauseDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchConfirmPauseDevice = (
  variables: ConfirmPauseDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ConfirmPauseDeviceError,
    undefined,
    {},
    {},
    ConfirmPauseDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/state/confirm-pause',
    method: 'post',
    ...variables,
    signal,
  });

export const useConfirmPauseDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ConfirmPauseDeviceError,
      ConfirmPauseDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ConfirmPauseDeviceError,
    ConfirmPauseDeviceVariables
  >({
    mutationFn: (variables: ConfirmPauseDeviceVariables) =>
      fetchConfirmPauseDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CancelPauseDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type CancelPauseDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type CancelPauseDeviceVariables = {
  pathParams: CancelPauseDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCancelPauseDevice = (
  variables: CancelPauseDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    CancelPauseDeviceError,
    undefined,
    {},
    {},
    CancelPauseDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/state/cancel-pause',
    method: 'post',
    ...variables,
    signal,
  });

export const useCancelPauseDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CancelPauseDeviceError,
      CancelPauseDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CancelPauseDeviceError,
    CancelPauseDeviceVariables
  >({
    mutationFn: (variables: CancelPauseDeviceVariables) =>
      fetchCancelPauseDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResumeDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type ResumeDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type ResumeDeviceVariables = {
  body: Schemas.Reason;
  pathParams: ResumeDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchResumeDevice = (
  variables: ResumeDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ResumeDeviceError,
    Schemas.Reason,
    {},
    {},
    ResumeDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/state/resume',
    method: 'post',
    ...variables,
    signal,
  });

export const useResumeDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ResumeDeviceError,
      ResumeDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ResumeDeviceError,
    ResumeDeviceVariables
  >({
    mutationFn: (variables: ResumeDeviceVariables) =>
      fetchResumeDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type StopDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type StopDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type StopDeviceRequestBody = Schemas.Reason & {
  payload: Schemas.StopReason;
};

export type StopDeviceVariables = {
  body?: StopDeviceRequestBody;
  pathParams: StopDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchStopDevice = (
  variables: StopDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    StopDeviceError,
    StopDeviceRequestBody,
    {},
    {},
    StopDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/state/stop',
    method: 'post',
    ...variables,
    signal,
  });

export const useStopDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      StopDeviceError,
      StopDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    StopDeviceError,
    StopDeviceVariables
  >({
    mutationFn: (variables: StopDeviceVariables) =>
      fetchStopDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResetDevicePathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type ResetDeviceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type ResetDeviceVariables = {
  body: Schemas.Reason;
  pathParams: ResetDevicePathParams;
} & ApiContext['fetcherOptions'];

export const fetchResetDevice = (
  variables: ResetDeviceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ResetDeviceError,
    Schemas.Reason,
    {},
    {},
    ResetDevicePathParams
  >({
    url: '/v1/devices/{deviceID}/state/reset',
    method: 'post',
    ...variables,
    signal,
  });

export const useResetDevice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ResetDeviceError,
      ResetDeviceVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ResetDeviceError,
    ResetDeviceVariables
  >({
    mutationFn: (variables: ResetDeviceVariables) =>
      fetchResetDevice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RespondDeviceAlertPathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type RespondDeviceAlertError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.WrongStateError;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type RespondDeviceAlertVariables = {
  body: Schemas.AlertResponse;
  pathParams: RespondDeviceAlertPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRespondDeviceAlert = (
  variables: RespondDeviceAlertVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    RespondDeviceAlertError,
    Schemas.AlertResponse,
    {},
    {},
    RespondDeviceAlertPathParams
  >({
    url: '/v1/devices/{deviceID}/state/alert-response',
    method: 'post',
    ...variables,
    signal,
  });

export const useRespondDeviceAlert = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RespondDeviceAlertError,
      RespondDeviceAlertVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RespondDeviceAlertError,
    RespondDeviceAlertVariables
  >({
    mutationFn: (variables: RespondDeviceAlertVariables) =>
      fetchRespondDeviceAlert({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ActionDeviceErrorPathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
  /**
   * The ID of the device error to affect.
   */
  deviceErrorID: Schemas.Uuid;
};

export type ActionDeviceErrorError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type ActionDeviceErrorVariables = {
  body: Schemas.DeviceErrorActionPayload;
  pathParams: ActionDeviceErrorPathParams;
} & ApiContext['fetcherOptions'];

export const fetchActionDeviceError = (
  variables: ActionDeviceErrorVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ActionDeviceErrorError,
    Schemas.DeviceErrorActionPayload,
    {},
    {},
    ActionDeviceErrorPathParams
  >({
    url: '/v1/devices/{deviceID}/errors/{deviceErrorID}/action',
    method: 'post',
    ...variables,
    signal,
  });

export const useActionDeviceError = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ActionDeviceErrorError,
      ActionDeviceErrorVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ActionDeviceErrorError,
    ActionDeviceErrorVariables
  >({
    mutationFn: (variables: ActionDeviceErrorVariables) =>
      fetchActionDeviceError({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type IgnoreTimeConstraintBrokenPromptPathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
  /**
   * The ID of the time constraint broken prompt to affect.
   */
  timeConstraintBrokenPromptID: Schemas.Uuid;
};

export type IgnoreTimeConstraintBrokenPromptError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type IgnoreTimeConstraintBrokenPromptVariables = {
  pathParams: IgnoreTimeConstraintBrokenPromptPathParams;
} & ApiContext['fetcherOptions'];

export const fetchIgnoreTimeConstraintBrokenPrompt = (
  variables: IgnoreTimeConstraintBrokenPromptVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    IgnoreTimeConstraintBrokenPromptError,
    undefined,
    {},
    {},
    IgnoreTimeConstraintBrokenPromptPathParams
  >({
    url: '/v1/devices/{deviceID}/time-constraints/{timeConstraintBrokenPromptID}/ignore',
    method: 'post',
    ...variables,
    signal,
  });

export const useIgnoreTimeConstraintBrokenPrompt = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      IgnoreTimeConstraintBrokenPromptError,
      IgnoreTimeConstraintBrokenPromptVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    IgnoreTimeConstraintBrokenPromptError,
    IgnoreTimeConstraintBrokenPromptVariables
  >({
    mutationFn: (variables: IgnoreTimeConstraintBrokenPromptVariables) =>
      fetchIgnoreTimeConstraintBrokenPrompt({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type UpdateDeviceGroupV2PathParams = {
  /**
   * The ID of the group to update.
   */
  groupID: Schemas.Uuid;
};

export type UpdateDeviceGroupV2Headers = {
  ['X-Request-Authorization']?: string;
};

export type UpdateDeviceGroupV2Error = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type UpdateDeviceGroupV2Variables = {
  body: Schemas.Group;
  headers?: UpdateDeviceGroupV2Headers;
  pathParams: UpdateDeviceGroupV2PathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateDeviceGroupV2 = (
  variables: UpdateDeviceGroupV2Variables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.FlatGroup,
    UpdateDeviceGroupV2Error,
    Schemas.Group,
    UpdateDeviceGroupV2Headers,
    {},
    UpdateDeviceGroupV2PathParams
  >({
    url: '/v2/device-groups/{groupID}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateDeviceGroupV2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FlatGroup,
      UpdateDeviceGroupV2Error,
      UpdateDeviceGroupV2Variables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.FlatGroup,
    UpdateDeviceGroupV2Error,
    UpdateDeviceGroupV2Variables
  >({
    mutationFn: (variables: UpdateDeviceGroupV2Variables) =>
      fetchUpdateDeviceGroupV2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteDeviceGroupV2PathParams = {
  /**
   * The ID of the group to delete.
   */
  groupID: Schemas.Uuid;
};

export type DeleteDeviceGroupV2Headers = {
  ['X-Request-Authorization']?: string;
};

export type DeleteDeviceGroupV2Error = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type DeleteDeviceGroupV2Variables = {
  headers?: DeleteDeviceGroupV2Headers;
  pathParams: DeleteDeviceGroupV2PathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteDeviceGroupV2 = (
  variables: DeleteDeviceGroupV2Variables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DeleteDeviceGroupV2Error,
    undefined,
    DeleteDeviceGroupV2Headers,
    {},
    DeleteDeviceGroupV2PathParams
  >({
    url: '/v2/device-groups/{groupID}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteDeviceGroupV2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteDeviceGroupV2Error,
      DeleteDeviceGroupV2Variables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteDeviceGroupV2Error,
    DeleteDeviceGroupV2Variables
  >({
    mutationFn: (variables: DeleteDeviceGroupV2Variables) =>
      fetchDeleteDeviceGroupV2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MoveDeviceBetweenGroupsV2PathParams = {
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type MoveDeviceBetweenGroupsV2Headers = {
  ['X-Request-Authorization']?: string;
};

export type MoveDeviceBetweenGroupsV2Error = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type MoveDeviceBetweenGroupsV2Variables = {
  body?: Schemas.MovePayload;
  headers?: MoveDeviceBetweenGroupsV2Headers;
  pathParams: MoveDeviceBetweenGroupsV2PathParams;
} & ApiContext['fetcherOptions'];

export const fetchMoveDeviceBetweenGroupsV2 = (
  variables: MoveDeviceBetweenGroupsV2Variables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.MoveResult,
    MoveDeviceBetweenGroupsV2Error,
    Schemas.MovePayload,
    MoveDeviceBetweenGroupsV2Headers,
    {},
    MoveDeviceBetweenGroupsV2PathParams
  >({
    url: '/v2/devices/{deviceID}/groups/move',
    method: 'post',
    ...variables,
    signal,
  });

export const useMoveDeviceBetweenGroupsV2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MoveResult,
      MoveDeviceBetweenGroupsV2Error,
      MoveDeviceBetweenGroupsV2Variables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.MoveResult,
    MoveDeviceBetweenGroupsV2Error,
    MoveDeviceBetweenGroupsV2Variables
  >({
    mutationFn: (variables: MoveDeviceBetweenGroupsV2Variables) =>
      fetchMoveDeviceBetweenGroupsV2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetWorkspaceWorkflowsPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
};

export type GetWorkspaceWorkflowsQueryParams = {
  /**
   * The cursor from which to start the listing, leave empty for the first request.
   * A cursor is only valid if you use the same options/arguments for each request.
   */
  cursor?: string;
  /**
   * what is the date & time of the first event to return, defaults to `7 days ago`
   *
   * @format date-time
   */
  range_start?: string;
  /**
   * what is the date & time of the last event to return, defaults to `now`
   *
   * @format date-time
   */
  range_end?: string;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  count?: number;
  /**
   * What property to use to order the entries? Defaults to `name`
   */
  order_by?: Schemas.PaginationOrderBy;
  /**
   * What order should the entries have? Defaults to `ascending`
   */
  order?: Schemas.PaginationOrder;
  /**
   * Regex filter (RE2 syntax) for the name of the items, defaults to empty, which returns everything
   */
  filter?: string;
  /**
   * The tag to filter by.
   */
  tags?: Schemas.Uuid[];
};

export type GetWorkspaceWorkflowsHeaders = {
  ['X-Request-Authorization']?: string;
};

export type GetWorkspaceWorkflowsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkspaceWorkflowsVariables = {
  headers?: GetWorkspaceWorkflowsHeaders;
  pathParams: GetWorkspaceWorkflowsPathParams;
  queryParams?: GetWorkspaceWorkflowsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkspaceWorkflows = (
  variables: GetWorkspaceWorkflowsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Workflows,
    GetWorkspaceWorkflowsError,
    undefined,
    GetWorkspaceWorkflowsHeaders,
    GetWorkspaceWorkflowsQueryParams,
    GetWorkspaceWorkflowsPathParams
  >({
    url: '/v1/workspace/{workspaceID}/workflows',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkspaceWorkflows = <TData = Schemas.Workflows>(
  variables: GetWorkspaceWorkflowsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Workflows,
      GetWorkspaceWorkflowsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.Workflows,
    GetWorkspaceWorkflowsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/workspace/{workspaceID}/workflows',
      operationId: 'getWorkspaceWorkflows',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkspaceWorkflows({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateWorkflowPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
};

export type CreateWorkflowError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type CreateWorkflowVariables = {
  body: Schemas.WorkflowCreatePayload;
  pathParams: CreateWorkflowPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCreateWorkflow = (
  variables: CreateWorkflowVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Workflow,
    CreateWorkflowError,
    Schemas.WorkflowCreatePayload,
    {},
    {},
    CreateWorkflowPathParams
  >({
    url: '/v1/workspace/{workspaceID}/workflows',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateWorkflow = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Workflow,
      CreateWorkflowError,
      CreateWorkflowVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Workflow,
    CreateWorkflowError,
    CreateWorkflowVariables
  >({
    mutationFn: (variables: CreateWorkflowVariables) =>
      fetchCreateWorkflow({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetWorkspaceDevicesPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
};

export type GetWorkspaceDevicesQueryParams = {
  /**
   * The cursor from which to start the listing, leave empty for the first request.
   * A cursor is only valid if you use the same options/arguments for each request.
   */
  cursor?: string;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  count?: number;
  /**
   * What property to use to order the entries? Defaults to `name`
   */
  order_by?: Schemas.PaginationOrderBy;
  /**
   * What order should the entries have? Defaults to `ascending`
   */
  order?: Schemas.PaginationOrder;
  /**
   * Regex filter (RE2 syntax) for the name of the items, defaults to empty, which returns everything
   */
  filter?: string;
};

export type GetWorkspaceDevicesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkspaceDevicesVariables = {
  pathParams: GetWorkspaceDevicesPathParams;
  queryParams?: GetWorkspaceDevicesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkspaceDevices = (
  variables: GetWorkspaceDevicesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Devices,
    GetWorkspaceDevicesError,
    undefined,
    {},
    GetWorkspaceDevicesQueryParams,
    GetWorkspaceDevicesPathParams
  >({
    url: '/v1/workspace/{workspaceID}/devices',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkspaceDevices = <TData = Schemas.Devices>(
  variables: GetWorkspaceDevicesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Devices,
      GetWorkspaceDevicesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.Devices, GetWorkspaceDevicesError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/workspace/{workspaceID}/devices',
      operationId: 'getWorkspaceDevices',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkspaceDevices({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetWorkspaceDeviceGroupsV2PathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
};

export type GetWorkspaceDeviceGroupsV2QueryParams = {
  /**
   * The cursor from which to start the listing, leave empty for the first request.
   * A cursor is only valid if you use the same options/arguments for each request.
   */
  cursor?: string;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  count?: number;
  /**
   * What property to use to order the entries? Defaults to `name`
   */
  order_by?: Schemas.PaginationOrderBy;
  /**
   * What order should the entries have? Defaults to `ascending`
   */
  order?: Schemas.PaginationOrder;
  /**
   * Regex filter (RE2 syntax) for the name of the items, defaults to empty, which returns everything
   */
  filter?: string;
};

export type GetWorkspaceDeviceGroupsV2Error = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkspaceDeviceGroupsV2Variables = {
  pathParams: GetWorkspaceDeviceGroupsV2PathParams;
  queryParams?: GetWorkspaceDeviceGroupsV2QueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkspaceDeviceGroupsV2 = (
  variables: GetWorkspaceDeviceGroupsV2Variables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.PaginatedFlatGroups,
    GetWorkspaceDeviceGroupsV2Error,
    undefined,
    {},
    GetWorkspaceDeviceGroupsV2QueryParams,
    GetWorkspaceDeviceGroupsV2PathParams
  >({
    url: '/v2/workspace/{workspaceID}/device-groups',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkspaceDeviceGroupsV2 = <
  TData = Schemas.PaginatedFlatGroups
>(
  variables: GetWorkspaceDeviceGroupsV2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PaginatedFlatGroups,
      GetWorkspaceDeviceGroupsV2Error,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PaginatedFlatGroups,
    GetWorkspaceDeviceGroupsV2Error,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v2/workspace/{workspaceID}/device-groups',
      operationId: 'getWorkspaceDeviceGroupsV2',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkspaceDeviceGroupsV2(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type CreateDeviceGroupV2PathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
};

export type CreateDeviceGroupV2Headers = {
  ['X-Request-Authorization']?: string;
};

export type CreateDeviceGroupV2Error = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type CreateDeviceGroupV2Variables = {
  body: Schemas.Group;
  headers?: CreateDeviceGroupV2Headers;
  pathParams: CreateDeviceGroupV2PathParams;
} & ApiContext['fetcherOptions'];

export const fetchCreateDeviceGroupV2 = (
  variables: CreateDeviceGroupV2Variables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.FlatGroup,
    CreateDeviceGroupV2Error,
    Schemas.Group,
    CreateDeviceGroupV2Headers,
    {},
    CreateDeviceGroupV2PathParams
  >({
    url: '/v2/workspace/{workspaceID}/device-groups',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateDeviceGroupV2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FlatGroup,
      CreateDeviceGroupV2Error,
      CreateDeviceGroupV2Variables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.FlatGroup,
    CreateDeviceGroupV2Error,
    CreateDeviceGroupV2Variables
  >({
    mutationFn: (variables: CreateDeviceGroupV2Variables) =>
      fetchCreateDeviceGroupV2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserOrganizationsV2Error = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetUserOrganizationsV2Variables = ApiContext['fetcherOptions'];

export const fetchGetUserOrganizationsV2 = (
  variables: GetUserOrganizationsV2Variables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.OrganizationsV2,
    GetUserOrganizationsV2Error,
    undefined,
    {},
    {},
    {}
  >({ url: '/v2/user/organizations', method: 'get', ...variables, signal });

export const useGetUserOrganizationsV2 = <TData = Schemas.OrganizationsV2>(
  variables: GetUserOrganizationsV2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrganizationsV2,
      GetUserOrganizationsV2Error,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrganizationsV2,
    GetUserOrganizationsV2Error,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v2/user/organizations',
      operationId: 'getUserOrganizationsV2',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserOrganizationsV2({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetUserError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetUserVariables = ApiContext['fetcherOptions'];

export const fetchGetUser = (
  variables: GetUserVariables,
  signal?: AbortSignal
) =>
  apiFetch<Schemas.User, GetUserError, undefined, {}, {}, {}>({
    url: '/v1/user',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetUser = <TData = Schemas.User>(
  variables: GetUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.User, GetUserError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.User, GetUserError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/user',
      operationId: 'getUser',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUser({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type RequestIntentionError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type RequestIntentionVariables = {
  body: Schemas.RequestIntentionRequest;
} & ApiContext['fetcherOptions'];

export const fetchRequestIntention = (
  variables: RequestIntentionVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.RequestIntentionResponse,
    RequestIntentionError,
    Schemas.RequestIntentionRequest,
    {},
    {},
    {}
  >({ url: '/v1/request/intention', method: 'post', ...variables, signal });

export const useRequestIntention = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RequestIntentionResponse,
      RequestIntentionError,
      RequestIntentionVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.RequestIntentionResponse,
    RequestIntentionError,
    RequestIntentionVariables
  >({
    mutationFn: (variables: RequestIntentionVariables) =>
      fetchRequestIntention({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RequestVerifyHeaders = {
  ['X-Id-Token']: string;
};

export type RequestVerifyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type RequestVerifyVariables = {
  body: Schemas.RequestVerifyRequest;
  headers: RequestVerifyHeaders;
} & ApiContext['fetcherOptions'];

export const fetchRequestVerify = (
  variables: RequestVerifyVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.RequestVerifyResponse,
    RequestVerifyError,
    Schemas.RequestVerifyRequest,
    RequestVerifyHeaders,
    {},
    {}
  >({ url: '/v1/request/verify', method: 'post', ...variables, signal });

export const useRequestVerify = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RequestVerifyResponse,
      RequestVerifyError,
      RequestVerifyVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.RequestVerifyResponse,
    RequestVerifyError,
    RequestVerifyVariables
  >({
    mutationFn: (variables: RequestVerifyVariables) =>
      fetchRequestVerify({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutUserNotificationsPreferencesPathParams = {
  /**
   * The ID of the user to affect.
   */
  userID: Schemas.Uuid;
};

export type PutUserNotificationsPreferencesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type PutUserNotificationsPreferencesVariables = {
  body: Schemas.UserNotificationPreferences;
  pathParams: PutUserNotificationsPreferencesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutUserNotificationsPreferences = (
  variables: PutUserNotificationsPreferencesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.UserNotificationPreferences,
    PutUserNotificationsPreferencesError,
    Schemas.UserNotificationPreferences,
    {},
    {},
    PutUserNotificationsPreferencesPathParams
  >({
    url: '/v1/users/{userID}/notifications/preferences',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutUserNotificationsPreferences = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserNotificationPreferences,
      PutUserNotificationsPreferencesError,
      PutUserNotificationsPreferencesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.UserNotificationPreferences,
    PutUserNotificationsPreferencesError,
    PutUserNotificationsPreferencesVariables
  >({
    mutationFn: (variables: PutUserNotificationsPreferencesVariables) =>
      fetchPutUserNotificationsPreferences({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserNotificationsPreferencesPathParams = {
  /**
   * The ID of the user to affect.
   */
  userID: Schemas.Uuid;
};

export type GetUserNotificationsPreferencesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetUserNotificationsPreferencesVariables = {
  pathParams: GetUserNotificationsPreferencesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetUserNotificationsPreferences = (
  variables: GetUserNotificationsPreferencesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.UserNotificationPreferences,
    GetUserNotificationsPreferencesError,
    undefined,
    {},
    {},
    GetUserNotificationsPreferencesPathParams
  >({
    url: '/v1/users/{userID}/notifications/preferences',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetUserNotificationsPreferences = <
  TData = Schemas.UserNotificationPreferences
>(
  variables: GetUserNotificationsPreferencesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UserNotificationPreferences,
      GetUserNotificationsPreferencesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.UserNotificationPreferences,
    GetUserNotificationsPreferencesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/users/{userID}/notifications/preferences',
      operationId: 'getUserNotificationsPreferences',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserNotificationsPreferences(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostDeviceNotificationPreferencesPathParams = {
  /**
   * The ID of the user to affect.
   */
  userID: Schemas.Uuid;
  /**
   * The ID of the device to affect.
   */
  deviceID: Schemas.Uuid;
};

export type PostDeviceNotificationPreferencesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type PostDeviceNotificationPreferencesVariables = {
  body: Schemas.DeviceNotificationPreferences;
  pathParams: PostDeviceNotificationPreferencesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostDeviceNotificationPreferences = (
  variables: PostDeviceNotificationPreferencesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    PostDeviceNotificationPreferencesError,
    Schemas.DeviceNotificationPreferences,
    {},
    {},
    PostDeviceNotificationPreferencesPathParams
  >({
    url: '/v1/users/{userID}/notifications/devices/{deviceID}/preferences',
    method: 'put',
    ...variables,
    signal,
  });

export const usePostDeviceNotificationPreferences = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PostDeviceNotificationPreferencesError,
      PostDeviceNotificationPreferencesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PostDeviceNotificationPreferencesError,
    PostDeviceNotificationPreferencesVariables
  >({
    mutationFn: (variables: PostDeviceNotificationPreferencesVariables) =>
      fetchPostDeviceNotificationPreferences({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetOrganizationsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetOrganizationsVariables = ApiContext['fetcherOptions'];

export const fetchGetOrganizations = (
  variables: GetOrganizationsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.OrganizationsV2,
    GetOrganizationsError,
    undefined,
    {},
    {},
    {}
  >({ url: '/v1/organizations', method: 'get', ...variables, signal });

export const useGetOrganizations = <TData = Schemas.OrganizationsV2>(
  variables: GetOrganizationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrganizationsV2,
      GetOrganizationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrganizationsV2,
    GetOrganizationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/organizations',
      operationId: 'getOrganizations',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetOrganizations({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateOrganizationError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type CreateOrganizationVariables = {
  body: Schemas.OrganizationPayload;
} & ApiContext['fetcherOptions'];

export const fetchCreateOrganization = (
  variables: CreateOrganizationVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Organization,
    CreateOrganizationError,
    Schemas.OrganizationPayload,
    {},
    {},
    {}
  >({ url: '/v1/organizations', method: 'post', ...variables, signal });

export const useCreateOrganization = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Organization,
      CreateOrganizationError,
      CreateOrganizationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Organization,
    CreateOrganizationError,
    CreateOrganizationVariables
  >({
    mutationFn: (variables: CreateOrganizationVariables) =>
      fetchCreateOrganization({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetOrganizationPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
};

export type GetOrganizationError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetOrganizationVariables = {
  pathParams: GetOrganizationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetOrganization = (
  variables: GetOrganizationVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Organization,
    GetOrganizationError,
    undefined,
    {},
    {},
    GetOrganizationPathParams
  >({
    url: '/v1/organizations/{organizationID}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetOrganization = <TData = Schemas.Organization>(
  variables: GetOrganizationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Organization,
      GetOrganizationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.Organization, GetOrganizationError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/v1/organizations/{organizationID}',
        operationId: 'getOrganization',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchGetOrganization({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }
  );
};

export type UpdateOrganizationPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
};

export type UpdateOrganizationHeaders = {
  ['X-Request-Authorization']?: string;
};

export type UpdateOrganizationError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type UpdateOrganizationRequestBody = Schemas.Reason & {
  payload: Schemas.OrganizationPayload;
};

export type UpdateOrganizationVariables = {
  body?: UpdateOrganizationRequestBody;
  headers?: UpdateOrganizationHeaders;
  pathParams: UpdateOrganizationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateOrganization = (
  variables: UpdateOrganizationVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Organization,
    UpdateOrganizationError,
    UpdateOrganizationRequestBody,
    UpdateOrganizationHeaders,
    {},
    UpdateOrganizationPathParams
  >({
    url: '/v1/organizations/{organizationID}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateOrganization = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Organization,
      UpdateOrganizationError,
      UpdateOrganizationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Organization,
    UpdateOrganizationError,
    UpdateOrganizationVariables
  >({
    mutationFn: (variables: UpdateOrganizationVariables) =>
      fetchUpdateOrganization({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteOrganizationPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
};

export type DeleteOrganizationHeaders = {
  ['X-Request-Authorization']?: string;
};

export type DeleteOrganizationError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type DeleteOrganizationVariables = {
  body: Schemas.Reason;
  headers?: DeleteOrganizationHeaders;
  pathParams: DeleteOrganizationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteOrganization = (
  variables: DeleteOrganizationVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DeleteOrganizationError,
    Schemas.Reason,
    DeleteOrganizationHeaders,
    {},
    DeleteOrganizationPathParams
  >({
    url: '/v1/organizations/{organizationID}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteOrganization = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteOrganizationError,
      DeleteOrganizationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteOrganizationError,
    DeleteOrganizationVariables
  >({
    mutationFn: (variables: DeleteOrganizationVariables) =>
      fetchDeleteOrganization({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUsersPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
};

export type GetUsersQueryParams = {
  /**
   * The cursor from which to start the listing, leave empty for the first request.
   * A cursor is only valid if you use the same options/arguments for each request.
   */
  cursor?: string;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  count?: number;
  /**
   * What order should the entries have? Defaults to `ascending`
   */
  order?: Schemas.PaginationOrder;
  /**
   * What property to use to order the entries? Defaults to `name`
   */
  order_by?: Schemas.PaginationOrderBy;
  /**
   * A string used to filter results by.
   */
  filter?: string;
};

export type GetUsersError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetUsersVariables = {
  pathParams: GetUsersPathParams;
  queryParams?: GetUsersQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetUsers = (
  variables: GetUsersVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Users,
    GetUsersError,
    undefined,
    {},
    GetUsersQueryParams,
    GetUsersPathParams
  >({
    url: '/v1/organizations/{organizationID}/users',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetUsers = <TData = Schemas.Users>(
  variables: GetUsersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Users, GetUsersError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.Users, GetUsersError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/organizations/{organizationID}/users',
      operationId: 'getUsers',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUsers({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type RegisterUserPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
};

export type RegisterUserError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type RegisterUserRequestBody = Schemas.Reason & {
  payload: Schemas.RegisterUserPayload;
};

export type RegisterUserVariables = {
  body?: RegisterUserRequestBody;
  pathParams: RegisterUserPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRegisterUser = (
  variables: RegisterUserVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    RegisterUserError,
    RegisterUserRequestBody,
    {},
    {},
    RegisterUserPathParams
  >({
    url: '/v1/organizations/{organizationID}/users',
    method: 'post',
    ...variables,
    signal,
  });

export const useRegisterUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RegisterUserError,
      RegisterUserVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RegisterUserError,
    RegisterUserVariables
  >({
    mutationFn: (variables: RegisterUserVariables) =>
      fetchRegisterUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateUserPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
  /**
   * The ID of the user to affect.
   */
  userID: Schemas.Uuid;
};

export type UpdateUserError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type UpdateUserRequestBody = Schemas.Reason & {
  payload: Schemas.UpdateUserPayload;
};

export type UpdateUserVariables = {
  body?: UpdateUserRequestBody;
  pathParams: UpdateUserPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateUser = (
  variables: UpdateUserVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.UserDetails,
    UpdateUserError,
    UpdateUserRequestBody,
    {},
    {},
    UpdateUserPathParams
  >({
    url: '/v1/organizations/{organizationID}/users/{userID}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserDetails,
      UpdateUserError,
      UpdateUserVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.UserDetails,
    UpdateUserError,
    UpdateUserVariables
  >({
    mutationFn: (variables: UpdateUserVariables) =>
      fetchUpdateUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveUserPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
  /**
   * The ID of the user to affect.
   */
  userID: Schemas.Uuid;
};

export type RemoveUserError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type RemoveUserVariables = {
  body: Schemas.Reason;
  pathParams: RemoveUserPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRemoveUser = (
  variables: RemoveUserVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    RemoveUserError,
    Schemas.Reason,
    {},
    {},
    RemoveUserPathParams
  >({
    url: '/v1/organizations/{organizationID}/users/{userID}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRemoveUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemoveUserError,
      RemoveUserVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RemoveUserError,
    RemoveUserVariables
  >({
    mutationFn: (variables: RemoveUserVariables) =>
      fetchRemoveUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserPermissionsPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
  /**
   * The ID of the user to affect.
   */
  userID: Schemas.Uuid;
};

export type GetUserPermissionsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetUserPermissionsVariables = {
  pathParams: GetUserPermissionsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetUserPermissions = (
  variables: GetUserPermissionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.UserPermissions,
    GetUserPermissionsError,
    undefined,
    {},
    {},
    GetUserPermissionsPathParams
  >({
    url: '/v1/organizations/{organizationID}/users/{userID}/permissions',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetUserPermissions = <TData = Schemas.UserPermissions>(
  variables: GetUserPermissionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UserPermissions,
      GetUserPermissionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.UserPermissions,
    GetUserPermissionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/organizations/{organizationID}/users/{userID}/permissions',
      operationId: 'getUserPermissions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserPermissions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateUserPermissionsPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
  /**
   * The ID of the user to affect.
   */
  userID: Schemas.Uuid;
};

export type UpdateUserPermissionsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type UpdateUserPermissionsRequestBody = Schemas.Reason & {
  payload: Schemas.UserPermissions;
};

export type UpdateUserPermissionsVariables = {
  body?: UpdateUserPermissionsRequestBody;
  pathParams: UpdateUserPermissionsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateUserPermissions = (
  variables: UpdateUserPermissionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.UserPermissions,
    UpdateUserPermissionsError,
    UpdateUserPermissionsRequestBody,
    {},
    {},
    UpdateUserPermissionsPathParams
  >({
    url: '/v1/organizations/{organizationID}/users/{userID}/permissions',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateUserPermissions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserPermissions,
      UpdateUserPermissionsError,
      UpdateUserPermissionsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.UserPermissions,
    UpdateUserPermissionsError,
    UpdateUserPermissionsVariables
  >({
    mutationFn: (variables: UpdateUserPermissionsVariables) =>
      fetchUpdateUserPermissions({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAuditLogsPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
};

export type GetAuditLogsQueryParams = {
  /**
   * The ID of the resource.
   */
  resourceID?: Schemas.Uuid;
  /**
   * The ID of the user.
   */
  userID?: Schemas.Uuid;
  /**
   * The cursor from which to start the listing, leave empty for the first request.
   * A cursor is only valid if you use the same options/arguments for each request.
   */
  cursor?: string;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  count?: number;
  /**
   * What order should the entries have? Defaults to `ascending`
   */
  order?: Schemas.PaginationOrder;
  /**
   * What property to use to order the entries? Defaults to `name`
   */
  order_by?: Schemas.PaginationOrderBy;
  /**
   * what is the date & time of the first event to return, defaults to `7 days ago`
   *
   * @format date-time
   */
  range_start?: string;
  /**
   * what is the date & time of the last event to return, defaults to `now`
   *
   * @format date-time
   */
  range_end?: string;
  /**
   * Regex filter (RE2 syntax) for the name of the items, defaults to empty, which returns everything
   */
  filter?: string;
};

export type GetAuditLogsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetAuditLogsVariables = {
  pathParams: GetAuditLogsPathParams;
  queryParams?: GetAuditLogsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAuditLogs = (
  variables: GetAuditLogsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.PaginatedAuditLogs,
    GetAuditLogsError,
    undefined,
    {},
    GetAuditLogsQueryParams,
    GetAuditLogsPathParams
  >({
    url: '/v1/organizations/{organizationID}/audit-logs',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAuditLogs = <TData = Schemas.PaginatedAuditLogs>(
  variables: GetAuditLogsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PaginatedAuditLogs,
      GetAuditLogsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PaginatedAuditLogs,
    GetAuditLogsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/organizations/{organizationID}/audit-logs',
      operationId: 'getAuditLogs',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAuditLogs({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ExportAuditLogsPathParams = {
  /**
   * The ID of the organization to query.
   */
  organizationID: Schemas.Uuid;
};

export type ExportAuditLogsQueryParams = {
  /**
   * what is the date & time of the first event to return, defaults to `7 days ago`
   *
   * @format date-time
   */
  range_start: string;
  /**
   * what is the date & time of the last event to return, defaults to `now`
   *
   * @format date-time
   */
  range_end: string;
};

export type ExportAuditLogsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type ExportAuditLogsVariables = {
  pathParams: ExportAuditLogsPathParams;
  queryParams: ExportAuditLogsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchExportAuditLogs = (
  variables: ExportAuditLogsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ExportAuditLogs,
    ExportAuditLogsError,
    undefined,
    {},
    ExportAuditLogsQueryParams,
    ExportAuditLogsPathParams
  >({
    url: '/v1/organizations/{organizationID}/audit-logs/export',
    method: 'get',
    ...variables,
    signal,
  });

export const useExportAuditLogs = <TData = Schemas.ExportAuditLogs>(
  variables: ExportAuditLogsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ExportAuditLogs,
      ExportAuditLogsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ExportAuditLogs,
    ExportAuditLogsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/organizations/{organizationID}/audit-logs/export',
      operationId: 'exportAuditLogs',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchExportAuditLogs({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPermissionsError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.InternalError;
}>;

export type GetPermissionsVariables = ApiContext['fetcherOptions'];

export const fetchGetPermissions = (
  variables: GetPermissionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<Schemas.Permissions, GetPermissionsError, undefined, {}, {}, {}>({
    url: '/v1/permissions',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPermissions = <TData = Schemas.Permissions>(
  variables: GetPermissionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Permissions, GetPermissionsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.Permissions, GetPermissionsError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/permissions',
      operationId: 'getPermissions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPermissions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ExchangeAuth0AblyTokenError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type ExchangeAuth0AblyTokenVariables = ApiContext['fetcherOptions'];

export const fetchExchangeAuth0AblyToken = (
  variables: ExchangeAuth0AblyTokenVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.AblyToken,
    ExchangeAuth0AblyTokenError,
    undefined,
    {},
    {},
    {}
  >({ url: '/v1/ws/token', method: 'post', ...variables, signal });

export const useExchangeAuth0AblyToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AblyToken,
      ExchangeAuth0AblyTokenError,
      ExchangeAuth0AblyTokenVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AblyToken,
    ExchangeAuth0AblyTokenError,
    ExchangeAuth0AblyTokenVariables
  >({
    mutationFn: (variables: ExchangeAuth0AblyTokenVariables) =>
      fetchExchangeAuth0AblyToken({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetWorkspaceTagsPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
};

export type GetWorkspaceTagsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkspaceTagsVariables = {
  pathParams: GetWorkspaceTagsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkspaceTags = (
  variables: GetWorkspaceTagsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Tags,
    GetWorkspaceTagsError,
    undefined,
    {},
    {},
    GetWorkspaceTagsPathParams
  >({
    url: '/v1/workspace/{workspaceID}/tags',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkspaceTags = <TData = Schemas.Tags>(
  variables: GetWorkspaceTagsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Tags, GetWorkspaceTagsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.Tags, GetWorkspaceTagsError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/workspace/{workspaceID}/tags',
      operationId: 'getWorkspaceTags',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkspaceTags({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateTagPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
};

export type CreateTagError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type CreateTagVariables = {
  body: Schemas.EditTag;
  pathParams: CreateTagPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCreateTag = (
  variables: CreateTagVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Tag,
    CreateTagError,
    Schemas.EditTag,
    {},
    {},
    CreateTagPathParams
  >({
    url: '/v1/workspace/{workspaceID}/tags',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateTag = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Tag,
      CreateTagError,
      CreateTagVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Tag,
    CreateTagError,
    CreateTagVariables
  >({
    mutationFn: (variables: CreateTagVariables) =>
      fetchCreateTag({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateTagPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the tag to affect.
   */
  tagID: Schemas.Uuid;
};

export type UpdateTagError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type UpdateTagVariables = {
  body: Schemas.EditTag;
  pathParams: UpdateTagPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateTag = (
  variables: UpdateTagVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Tag,
    UpdateTagError,
    Schemas.EditTag,
    {},
    {},
    UpdateTagPathParams
  >({
    url: '/v1/workspace/{workspaceID}/tags/{tagID}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateTag = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Tag,
      UpdateTagError,
      UpdateTagVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Tag,
    UpdateTagError,
    UpdateTagVariables
  >({
    mutationFn: (variables: UpdateTagVariables) =>
      fetchUpdateTag({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteTagPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the tag to affect.
   */
  tagID: Schemas.Uuid;
};

export type DeleteTagError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type DeleteTagVariables = {
  pathParams: DeleteTagPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteTag = (
  variables: DeleteTagVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, DeleteTagError, undefined, {}, {}, DeleteTagPathParams>({
    url: '/v1/workspace/{workspaceID}/tags/{tagID}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteTag = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteTagError,
      DeleteTagVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, DeleteTagError, DeleteTagVariables>({
    mutationFn: (variables: DeleteTagVariables) =>
      fetchDeleteTag({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ArchiveTagPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the tag to affect.
   */
  tagID: Schemas.Uuid;
};

export type ArchiveTagError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type ArchiveTagVariables = {
  pathParams: ArchiveTagPathParams;
} & ApiContext['fetcherOptions'];

export const fetchArchiveTag = (
  variables: ArchiveTagVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, ArchiveTagError, undefined, {}, {}, ArchiveTagPathParams>(
    {
      url: '/v1/workspace/{workspaceID}/tags/{tagID}/archive',
      method: 'post',
      ...variables,
      signal,
    }
  );

export const useArchiveTag = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ArchiveTagError,
      ArchiveTagVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ArchiveTagError,
    ArchiveTagVariables
  >({
    mutationFn: (variables: ArchiveTagVariables) =>
      fetchArchiveTag({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UnarchiveTagPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the tag to affect.
   */
  tagID: Schemas.Uuid;
};

export type UnarchiveTagError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type UnarchiveTagVariables = {
  pathParams: UnarchiveTagPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUnarchiveTag = (
  variables: UnarchiveTagVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    UnarchiveTagError,
    undefined,
    {},
    {},
    UnarchiveTagPathParams
  >({
    url: '/v1/workspace/{workspaceID}/tags/{tagID}/unarchive',
    method: 'post',
    ...variables,
    signal,
  });

export const useUnarchiveTag = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UnarchiveTagError,
      UnarchiveTagVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    UnarchiveTagError,
    UnarchiveTagVariables
  >({
    mutationFn: (variables: UnarchiveTagVariables) =>
      fetchUnarchiveTag({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetWorkcellsPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
};

export type GetWorkcellsQueryParams = {
  /**
   * A string used to filter results by.
   */
  filter?: string;
  /**
   * The page of results to return, leave empty for the first request.
   * Page results are affected by filters, order_by, order direction and page size.
   *
   * @minimum 1
   */
  page?: number;
  /**
   * How many results to return? Defaults to `100`
   *
   * @minimum 10
   * @maximum 1000
   */
  page_size?: number;
  /**
   * What property to use to order the workcells? Defaults to `descending` `last_access`
   */
  order_by?: Schemas.WorkcellsOrderBy;
  /**
   * What order should the entries have? Defaults to `ascending`
   */
  order?: Schemas.PaginationOrder;
};

export type GetWorkcellsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkcellsVariables = {
  pathParams: GetWorkcellsPathParams;
  queryParams?: GetWorkcellsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkcells = (
  variables: GetWorkcellsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Workcells,
    GetWorkcellsError,
    undefined,
    {},
    GetWorkcellsQueryParams,
    GetWorkcellsPathParams
  >({
    url: '/v1/workspace/{workspaceID}/workcells',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkcells = <TData = Schemas.Workcells>(
  variables: GetWorkcellsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Workcells, GetWorkcellsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.Workcells, GetWorkcellsError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/workspace/{workspaceID}/workcells',
      operationId: 'getWorkcells',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkcells({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetWorkcellDetailsPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the workcell.
   */
  workcellID: Schemas.Uuid;
};

export type GetWorkcellDetailsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkcellDetailsVariables = {
  pathParams: GetWorkcellDetailsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkcellDetails = (
  variables: GetWorkcellDetailsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.WorkcellDetailed,
    GetWorkcellDetailsError,
    undefined,
    {},
    {},
    GetWorkcellDetailsPathParams
  >({
    url: '/v1/workspace/{workspaceID}/workcells/{workcellID}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkcellDetails = <TData = Schemas.WorkcellDetailed>(
  variables: GetWorkcellDetailsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.WorkcellDetailed,
      GetWorkcellDetailsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.WorkcellDetailed,
    GetWorkcellDetailsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}',
      operationId: 'getWorkcellDetails',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkcellDetails({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateWorkcellMetadataPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the workcell.
   */
  workcellID: Schemas.Uuid;
};

export type UpdateWorkcellMetadataHeaders = {
  ['X-Request-Authorization']?: string;
};

export type UpdateWorkcellMetadataError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type UpdateWorkcellMetadataRequestBody = Schemas.Reason & {
  payload: Schemas.WorkcellMetadata;
};

export type UpdateWorkcellMetadataVariables = {
  body?: UpdateWorkcellMetadataRequestBody;
  headers?: UpdateWorkcellMetadataHeaders;
  pathParams: UpdateWorkcellMetadataPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateWorkcellMetadata = (
  variables: UpdateWorkcellMetadataVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    UpdateWorkcellMetadataError,
    UpdateWorkcellMetadataRequestBody,
    UpdateWorkcellMetadataHeaders,
    {},
    UpdateWorkcellMetadataPathParams
  >({
    url: '/v1/workspace/{workspaceID}/workcells/{workcellID}/metadata',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateWorkcellMetadata = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateWorkcellMetadataError,
      UpdateWorkcellMetadataVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    UpdateWorkcellMetadataError,
    UpdateWorkcellMetadataVariables
  >({
    mutationFn: (variables: UpdateWorkcellMetadataVariables) =>
      fetchUpdateWorkcellMetadata({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetWorkcellCurrentWorkflowPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the workcell.
   */
  workcellID: Schemas.Uuid;
};

export type GetWorkcellCurrentWorkflowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkcellCurrentWorkflowVariables = {
  pathParams: GetWorkcellCurrentWorkflowPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkcellCurrentWorkflow = (
  variables: GetWorkcellCurrentWorkflowVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.WorkcellCurrentWorkflow,
    GetWorkcellCurrentWorkflowError,
    undefined,
    {},
    {},
    GetWorkcellCurrentWorkflowPathParams
  >({
    url: '/v1/workspace/{workspaceID}/workcells/{workcellID}/workflow',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkcellCurrentWorkflow = <
  TData = Schemas.WorkcellCurrentWorkflow
>(
  variables: GetWorkcellCurrentWorkflowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.WorkcellCurrentWorkflow,
      GetWorkcellCurrentWorkflowError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.WorkcellCurrentWorkflow,
    GetWorkcellCurrentWorkflowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}/workflow',
      operationId: 'getWorkcellCurrentWorkflow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkcellCurrentWorkflow(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetWorkcellCurrentRunPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the workcell.
   */
  workcellID: Schemas.Uuid;
};

export type GetWorkcellCurrentRunError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkcellCurrentRunVariables = {
  pathParams: GetWorkcellCurrentRunPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkcellCurrentRun = (
  variables: GetWorkcellCurrentRunVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.WorkcellCurrentRun,
    GetWorkcellCurrentRunError,
    undefined,
    {},
    {},
    GetWorkcellCurrentRunPathParams
  >({
    url: '/v1/workspace/{workspaceID}/workcells/{workcellID}/run',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkcellCurrentRun = <TData = Schemas.WorkcellCurrentRun>(
  variables: GetWorkcellCurrentRunVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.WorkcellCurrentRun,
      GetWorkcellCurrentRunError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.WorkcellCurrentRun,
    GetWorkcellCurrentRunError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}/run',
      operationId: 'getWorkcellCurrentRun',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkcellCurrentRun({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetWorkcellCurrentRunParametersPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the workcell.
   */
  workcellID: Schemas.Uuid;
};

export type GetWorkcellCurrentRunParametersError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkcellCurrentRunParametersVariables = {
  pathParams: GetWorkcellCurrentRunParametersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkcellCurrentRunParameters = (
  variables: GetWorkcellCurrentRunParametersVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.WorkcellCurrentRunParameterValues,
    GetWorkcellCurrentRunParametersError,
    undefined,
    {},
    {},
    GetWorkcellCurrentRunParametersPathParams
  >({
    url: '/v1/workspace/{workspaceID}/workcells/{workcellID}/run/parameters',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkcellCurrentRunParameters = <
  TData = Schemas.WorkcellCurrentRunParameterValues
>(
  variables: GetWorkcellCurrentRunParametersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.WorkcellCurrentRunParameterValues,
      GetWorkcellCurrentRunParametersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.WorkcellCurrentRunParameterValues,
    GetWorkcellCurrentRunParametersError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}/run/parameters',
      operationId: 'getWorkcellCurrentRunParameters',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkcellCurrentRunParameters(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetWorkcellCurrentReferenceDataPathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the workcell.
   */
  workcellID: Schemas.Uuid;
};

export type GetWorkcellCurrentReferenceDataError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkcellCurrentReferenceDataVariables = {
  pathParams: GetWorkcellCurrentReferenceDataPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkcellCurrentReferenceData = (
  variables: GetWorkcellCurrentReferenceDataVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.WorkcellCurrentReferenceDataValues,
    GetWorkcellCurrentReferenceDataError,
    undefined,
    {},
    {},
    GetWorkcellCurrentReferenceDataPathParams
  >({
    url: '/v1/workspace/{workspaceID}/workcells/{workcellID}/run/reference-data',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkcellCurrentReferenceData = <
  TData = Schemas.WorkcellCurrentReferenceDataValues
>(
  variables: GetWorkcellCurrentReferenceDataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.WorkcellCurrentReferenceDataValues,
      GetWorkcellCurrentReferenceDataError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.WorkcellCurrentReferenceDataValues,
    GetWorkcellCurrentReferenceDataError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}/run/reference-data',
      operationId: 'getWorkcellCurrentReferenceData',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkcellCurrentReferenceData(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type UpdateWorkcellModePathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the workcell.
   */
  workcellID: Schemas.Uuid;
};

export type UpdateWorkcellModeHeaders = {
  ['X-Request-Authorization']?: string;
};

export type UpdateWorkcellModeError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type UpdateWorkcellModeRequestBody = Schemas.Reason & {
  payload: Schemas.WorkcellMode;
};

export type UpdateWorkcellModeVariables = {
  body?: UpdateWorkcellModeRequestBody;
  headers?: UpdateWorkcellModeHeaders;
  pathParams: UpdateWorkcellModePathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateWorkcellMode = (
  variables: UpdateWorkcellModeVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    UpdateWorkcellModeError,
    UpdateWorkcellModeRequestBody,
    UpdateWorkcellModeHeaders,
    {},
    UpdateWorkcellModePathParams
  >({
    url: '/v1/workspace/{workspaceID}/workcells/{workcellID}/mode',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateWorkcellMode = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateWorkcellModeError,
      UpdateWorkcellModeVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    UpdateWorkcellModeError,
    UpdateWorkcellModeVariables
  >({
    mutationFn: (variables: UpdateWorkcellModeVariables) =>
      fetchUpdateWorkcellMode({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetWorkcellImagePathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the workcell.
   */
  workcellID: Schemas.Uuid;
};

export type GetWorkcellImageError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type GetWorkcellImageVariables = {
  pathParams: GetWorkcellImagePathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkcellImage = (
  variables: GetWorkcellImageVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    GetWorkcellImageError,
    undefined,
    {},
    {},
    GetWorkcellImagePathParams
  >({
    url: '/v1/workspace/{workspaceID}/workcells/{workcellID}/image',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkcellImage = <TData = undefined>(
  variables: GetWorkcellImageVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetWorkcellImageError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, GetWorkcellImageError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}/image',
      operationId: 'getWorkcellImage',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkcellImage({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateWorkcellImagePathParams = {
  /**
   * The ID of the workspace to use.
   */
  workspaceID: Schemas.Uuid;
  /**
   * The ID of the workcell.
   */
  workcellID: Schemas.Uuid;
};

export type UpdateWorkcellImageError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.InvalidArguments;
    }
  | {
      status: 401;
      payload: Responses.UnauthorizedError;
    }
  | {
      status: 404;
      payload: Responses.NotFoundError;
    }
  | {
      status: 500;
      payload: Responses.InternalError;
    }
>;

export type UpdateWorkcellImageVariables = {
  pathParams: UpdateWorkcellImagePathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateWorkcellImage = (
  variables: UpdateWorkcellImageVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    UpdateWorkcellImageError,
    undefined,
    {},
    {},
    UpdateWorkcellImagePathParams
  >({
    url: '/v1/workspace/{workspaceID}/workcells/{workcellID}/image',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateWorkcellImage = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateWorkcellImageError,
      UpdateWorkcellImageVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    UpdateWorkcellImageError,
    UpdateWorkcellImageVariables
  >({
    mutationFn: (variables: UpdateWorkcellImageVariables) =>
      fetchUpdateWorkcellImage({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: '/version';
      operationId: 'getVersion';
      variables: GetVersionVariables;
    }
  | {
      path: '/v1/run-histories/{runHistoryID}';
      operationId: 'getRunHistoryDetails';
      variables: GetRunHistoryDetailsVariables;
    }
  | {
      path: '/v1/run-histories/{runHistoryID}/logs/export';
      operationId: 'getRunHistoryLogsExport';
      variables: GetRunHistoryLogsExportVariables;
    }
  | {
      path: '/v1/run-histories/{runHistoryID}/progress';
      operationId: 'getRunProgress';
      variables: GetRunProgressVariables;
    }
  | {
      path: '/v1/run-histories/{runHistoryID}/event-log';
      operationId: 'getRunEventLog';
      variables: GetRunEventLogVariables;
    }
  | {
      path: '/v1/batches/{batchID}';
      operationId: 'getBatchDetails';
      variables: GetBatchDetailsVariables;
    }
  | {
      path: '/v1/workflows/{workflowID}';
      operationId: 'getWorkflowDetails';
      variables: GetWorkflowDetailsVariables;
    }
  | {
      path: '/v1/workflows/{workflowID}/deployment-candidates';
      operationId: 'getWorkflowDeploymentCandidates';
      variables: GetWorkflowDeploymentCandidatesVariables;
    }
  | {
      path: '/v1/maestro/versions';
      operationId: 'getMaestroVersions';
      variables: GetMaestroVersionsVariables;
    }
  | {
      path: '/v1/evals/versions';
      operationId: 'getEvalVersions';
      variables: GetEvalVersionsVariables;
    }
  | {
      path: '/v1/archetypes/versions';
      operationId: 'getArchetypeVersions';
      variables: GetArchetypeVersionsVariables;
    }
  | {
      path: '/v1/devices/{deviceIDOrSerial}';
      operationId: 'getDeviceData';
      variables: GetDeviceDataVariables;
    }
  | {
      path: '/v1/devices/{deviceID}/history/state';
      operationId: 'getDeviceStateHistory';
      variables: GetDeviceStateHistoryVariables;
    }
  | {
      path: '/v1/devices/{deviceID}/history/metrics';
      operationId: 'getDeviceMetricsHistory';
      variables: GetDeviceMetricsHistoryVariables;
    }
  | {
      path: '/v1/devices/{deviceID}/history/logs';
      operationId: 'getDeviceLogsHistory';
      variables: GetDeviceLogsHistoryVariables;
    }
  | {
      path: '/v1/devices/{deviceID}/history/run-histories';
      operationId: 'getDeviceRunHistoriesHistory';
      variables: GetDeviceRunHistoriesHistoryVariables;
    }
  | {
      path: '/v1/devices/{deviceID}/state/configure';
      operationId: 'getDeviceConfiguration';
      variables: GetDeviceConfigurationVariables;
    }
  | {
      path: '/v1/workspace/{workspaceID}/workflows';
      operationId: 'getWorkspaceWorkflows';
      variables: GetWorkspaceWorkflowsVariables;
    }
  | {
      path: '/v1/workspace/{workspaceID}/devices';
      operationId: 'getWorkspaceDevices';
      variables: GetWorkspaceDevicesVariables;
    }
  | {
      path: '/v2/workspace/{workspaceID}/device-groups';
      operationId: 'getWorkspaceDeviceGroupsV2';
      variables: GetWorkspaceDeviceGroupsV2Variables;
    }
  | {
      path: '/v2/user/organizations';
      operationId: 'getUserOrganizationsV2';
      variables: GetUserOrganizationsV2Variables;
    }
  | {
      path: '/v1/user';
      operationId: 'getUser';
      variables: GetUserVariables;
    }
  | {
      path: '/v1/users/{userID}/notifications/preferences';
      operationId: 'getUserNotificationsPreferences';
      variables: GetUserNotificationsPreferencesVariables;
    }
  | {
      path: '/v1/organizations';
      operationId: 'getOrganizations';
      variables: GetOrganizationsVariables;
    }
  | {
      path: '/v1/organizations/{organizationID}';
      operationId: 'getOrganization';
      variables: GetOrganizationVariables;
    }
  | {
      path: '/v1/organizations/{organizationID}/users';
      operationId: 'getUsers';
      variables: GetUsersVariables;
    }
  | {
      path: '/v1/organizations/{organizationID}/users/{userID}/permissions';
      operationId: 'getUserPermissions';
      variables: GetUserPermissionsVariables;
    }
  | {
      path: '/v1/organizations/{organizationID}/audit-logs';
      operationId: 'getAuditLogs';
      variables: GetAuditLogsVariables;
    }
  | {
      path: '/v1/organizations/{organizationID}/audit-logs/export';
      operationId: 'exportAuditLogs';
      variables: ExportAuditLogsVariables;
    }
  | {
      path: '/v1/permissions';
      operationId: 'getPermissions';
      variables: GetPermissionsVariables;
    }
  | {
      path: '/v1/workspace/{workspaceID}/tags';
      operationId: 'getWorkspaceTags';
      variables: GetWorkspaceTagsVariables;
    }
  | {
      path: '/v1/workspace/{workspaceID}/workcells';
      operationId: 'getWorkcells';
      variables: GetWorkcellsVariables;
    }
  | {
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}';
      operationId: 'getWorkcellDetails';
      variables: GetWorkcellDetailsVariables;
    }
  | {
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}/workflow';
      operationId: 'getWorkcellCurrentWorkflow';
      variables: GetWorkcellCurrentWorkflowVariables;
    }
  | {
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}/run';
      operationId: 'getWorkcellCurrentRun';
      variables: GetWorkcellCurrentRunVariables;
    }
  | {
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}/run/parameters';
      operationId: 'getWorkcellCurrentRunParameters';
      variables: GetWorkcellCurrentRunParametersVariables;
    }
  | {
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}/run/reference-data';
      operationId: 'getWorkcellCurrentReferenceData';
      variables: GetWorkcellCurrentReferenceDataVariables;
    }
  | {
      path: '/v1/workspace/{workspaceID}/workcells/{workcellID}/image';
      operationId: 'getWorkcellImage';
      variables: GetWorkcellImageVariables;
    };
