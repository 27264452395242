import {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '../Typography';
import { type DurationChangeHandler, type TimeBreakdown } from './types';
import {
  getTimeBreakdown,
  getTotalSeconds,
  isTimeBreakdown,
  padLeadingZero,
} from './utils';
import { InputBaseStyled, InputsWrap } from './styled';
import { useUnmount } from 'usehooks-ts';

export type DurationPickerProps = {
  onChange?: DurationChangeHandler;
  onBlur?: DurationChangeHandler;
  id?: string;
  label?: string;
  initialValue?: { totalSeconds: number } | TimeBreakdown;
  bgColor?: CSSProperties['backgroundColor'];
  error?: boolean;
  resetWhenZeroed?: boolean;
  fullWidth?: boolean;
};

export const DurationPicker = ({
  onChange = () => {},
  onBlur = () => {},
  id = `durationPicker-${uuidv4()}`,
  label,
  initialValue = {
    hours: null,
    minutes: null,
    seconds: null,
  },
  bgColor = 'transparent',
  error = false,
  resetWhenZeroed = false,
  fullWidth = false,
}: DurationPickerProps): JSX.Element => {
  const firstRun = useRef(true);
  const inputsWrapRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();

  const initialValueParsed = isTimeBreakdown(initialValue)
    ? initialValue
    : getTimeBreakdown(initialValue.totalSeconds);
  const {
    seconds: initialSeconds,
    minutes: initialMinutes,
    hours: initialHours,
  } = initialValueParsed;

  const [seconds, setSeconds] = useState<number | null>(initialSeconds);
  const [minutes, setMinutes] = useState<number | null>(initialMinutes);
  const [hours, setHours] = useState<number | null>(initialHours);

  const totalSeconds = useMemo(
    () => getTotalSeconds({ hours, minutes, seconds }),
    [hours, minutes, seconds]
  );

  type ValueSetter = (value: number | null) => void;
  type Input = {
    id: string;
    value: number | null;
    setValue: ValueSetter;
    placeholder: string;
  };

  const inputs: Input[] = [
    { id: 'hours', value: hours, setValue: setHours, placeholder: 'hh' },
    { id: 'minutes', value: minutes, setValue: setMinutes, placeholder: 'mm' },
    { id: 'seconds', value: seconds, setValue: setSeconds, placeholder: 'ss' },
  ];

  const handleInputChange = (value: string, setValue: ValueSetter) => {
    if (!/^\d*$/.test(value)) return;
    const newValue = value === '' ? null : Number(value);
    if (Number(newValue) > 99) return;
    setValue(newValue);
  };

  const handleAllInputsBlurred = useCallback(() => {
    if ([seconds, minutes, hours].every((val) => val === null)) {
      onBlur({
        hours: null,
        minutes: null,
        seconds: null,
        totalSeconds: null,
      });
      return;
    }

    if (
      resetWhenZeroed &&
      [seconds, minutes, hours].every((val) => val === 0)
    ) {
      setSeconds(null);
      setMinutes(null);
      setHours(null);
      onBlur({
        hours: null,
        minutes: null,
        seconds: null,
        totalSeconds: null,
      });
      return;
    }

    const {
      hours: newHours,
      minutes: newMinutes,
      seconds: newSeconds,
    } = getTimeBreakdown(totalSeconds);

    setSeconds(newSeconds ?? 0);
    setMinutes(newMinutes ?? 0);
    setHours(newHours ?? 0);

    onBlur({
      hours: newHours,
      minutes: newMinutes,
      seconds: newSeconds,
      get totalSeconds() {
        if (hours === null && minutes === null && seconds === null) return null;
        return getTotalSeconds({
          hours: newHours,
          minutes: newMinutes,
          seconds: newSeconds,
        });
      },
    });
  }, [hours, minutes, onBlur, resetWhenZeroed, seconds, totalSeconds]);

  const handleInputBlur = () => {
    // skip the current event loop to allow the next input to be focused
    setTimeout(() => {
      if (!inputsWrapRef.current?.contains(document.activeElement)) {
        handleAllInputsBlurred();
      }
    });
  };

  useEffect(() => {
    // dont call onChange on first render
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    onChange({
      hours,
      minutes,
      seconds,
      get totalSeconds() {
        if (hours === null && minutes === null && seconds === null) return null;
        return getTotalSeconds({ hours, minutes, seconds });
      },
    });
  }, [seconds, minutes, hours, onChange]);

  useUnmount(() => {
    handleAllInputsBlurred();
  });

  return (
    <Stack>
      {label && (
        <Typography
          htmlFor={id}
          component="label"
          variant="overline"
          color={theme.palette.text.primary}
          pt={1}
          pb={0.5}
        >
          {label}
        </Typography>
      )}

      <Typography variant="body2" component="span">
        <InputsWrap
          ref={inputsWrapRef}
          bgcolor={bgColor}
          className={error ? 'error' : undefined}
          maxWidth={fullWidth ? undefined : '10em'}
        >
          {inputs.map(({ id: inputId, value, setValue, placeholder }, i) => (
            <Fragment key={inputId}>
              <InputBaseStyled
                value={value === null ? '' : padLeadingZero(value)}
                onChange={(e) => handleInputChange(e.target.value, setValue)}
                onBlur={handleInputBlur}
                placeholder={placeholder}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Backspace') setValue(null);
                }}
              />
              {i < inputs.length - 1 && <span>:</span>}
            </Fragment>
          ))}
        </InputsWrap>
      </Typography>
    </Stack>
  );
};
