import { NextRouter, Router, useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { beforeUnloadHandler, routeChangeHandler } from './services';

type EnabledInput =
  | boolean
  | ((router: NextRouter, newRoute?: string) => boolean);

export const useConfirmNavigateAway = (enabled: EnabledInput = true) => {
  const router = useRouter();

  const getIsEnabled = useCallback(
    (newRoute?: string): boolean =>
      typeof enabled === 'function' ? enabled(router, newRoute) : enabled,
    [enabled, router]
  );

  useEffect(() => {
    // Return early if router is not initialised
    // Next typing seems a bit off here
    if (!router.beforePopState) return;

    // "Changes that you made may not be saved." is the default message for Chrome when using beforeunload
    // For consistency this is used here for next router pop state as well
    const confirmationMessage =
      'Navigate away from page? Changes that you made may not be saved.';

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      const isEnabled = getIsEnabled();
      if (!isEnabled) return;
      beforeUnloadHandler(e, confirmationMessage);
    };

    const handleRouteChange = (newRoute: string) => {
      const isEnabled = getIsEnabled(newRoute);
      if (!isEnabled) return;
      routeChangeHandler(confirmationMessage, router, Router);
    };

    const handleSetup = () => {
      window.addEventListener('beforeunload', handleBeforeUnload);
      Router.events.on('routeChangeStart', handleRouteChange);
    };

    const handleCleanup = () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      Router.events.off('routeChangeStart', handleRouteChange);
    };

    handleSetup();
    return handleCleanup;
  }, [getIsEnabled, router]);
};
